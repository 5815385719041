@import url('https://fonts.googleapis.com/css2?family=Quicksand&family=Poppins&family=Oswald&display=swap');

* {
  box-sizing: border-box;
font-family: 'Poppins', 'Quicksand', 'Oswald',  Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: black;
  color: white;
  margin-top: 10px;
}