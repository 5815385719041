@media screen and (max-width: 600px) {
    .movie-poster-review {
        width: 80vw;
        height: auto;
        /* border: red solid; */
    }
    
    .movie-poster-review img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .poster-column {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title-and-info {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: red solid; */
    }

    .genres {
        overflow-x: scroll;
        padding: 6px;
        /* border: red solid; */
    }

    .blur-effect {
        font-size: small;
    }
    
}

/* Initial state with blur */
.blur-effect {
    filter: blur(5px);
    opacity: 0;
    transition: filter 1.5s ease-out, opacity 1.5s ease-out;
}

/* Keyframes for the animation */
@keyframes blurFadeInOut {
    0% {
        filter: blur(5px);
        opacity: 0;
    }
    100% {
        filter: blur(0);
        opacity: 1;
    }
}

/* Animation trigger */
.animate-on-load {
    animation: blurFadeInOut 1.5s ease-out forwards;
}

/* The next style might mess up the whole website. be wary */
.background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    background-position: center;
    opacity: 0.3; /* Adjust this value for desired background image opacity */
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)), var(--img);
}

.review-container {
    position: relative;
    position: relative;
    animation: fadeIn 0.5s ease-out; 
    z-index: 5;
    z-index: 5;
}

.upvote-button, .downvote-button {
    /* color: rgb(6, 124, 6); */
    color: rgb(132, 132, 132);
    opacity: 0.5;
    transition: transform 0.15s;
}

.upvote-button:hover,
.downvote-button:hover {
    transform: scale(1.20);
    opacity: 1;
    cursor: pointer;
}

.movie-poster-review {
    position: relative;
    display: inline-block;
}

.movie-poster-review img {
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0,0,0,0.5);
}

.play-button-icon-container-review {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.genre-name {
    font-size: 14px;
    border: gray solid;
    border-radius: 8px;
    padding: 5px;
    margin-right: 10px;
    background-color: rgb(58, 58, 58);
    transition: opacity 0.15s;
}

.genre-name:hover {
    cursor: default;
    opacity: 0.8;
}

.genres {
    margin-bottom: 30px;
}

.review-info {
    /* border: 'red solid'; */
    display: flex;
    justify-content: space-between;
}