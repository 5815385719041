@keyframes fadeIn {
    from { 
        opacity: 1; 
        transform: translateY(10px);
    }
    to { 
        opacity: 1; 
        transform: translateY(0px);
    }
}

.all-content {
    padding-bottom: 80px;
}

.request-container {
    padding: 50px;
    margin-bottom: 20px;
    /* border: red solid; */
}

.search-movie-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10vw;
    margin-right: 10vw;
    border-top: rgb(58, 58, 58) solid;
    border-bottom: rgb(58, 58, 58) solid;

    animation: fadeIn 0.5s ease-out; 
    transition: transform 0.25s;
}

.search-movie-item:hover {
    background-color: rgb(20, 20, 20);
    cursor: pointer;
    transform: scale(1.02);
}

.search-movie-poster {
    display: flex;
    flex: 0 0 150px; /* Do not grow, do not shrink, start at 150px width */
    flex-direction: column;
    justify-content: center;
    margin: 2rem;
    /* border: blue solid; */
}

.search-movie-poster img {
    width: 100%; 
    height: auto;
    border-radius: 5px;
}

.search-movie-info {
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex: 1; 
}


@media screen and (max-width: 600px) {
    .all-content {
        font-size: small;
    }

    .search-movie-item {
        flex-direction: column;
        padding: 0 1rem;
    }
    .search-movie-info h5 {
        margin-top: 12px;
    }
    .search-movie-info p {
        font-size: 12px;
    }
}