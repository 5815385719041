@keyframes fadeIn {
    from { 
        opacity: 1; 
        transform: translateY(10px);
    }
    to { 
        opacity: 1; 
        transform: translateY(0px);
    }
}

.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px;  */
}  

.dropdowns {
    order: 2;
    display: flex;
    flex-direction: row;
}

.genre-dropdown {
    margin-right: 15px;
}

.sort-dropdown .selected-dropdown-item,
.genre-dropdown .selected-dropdown-item {
    background-color: rgb(211, 211, 211) !important;
}

.greeting {
    order: 1; 
}

.movie-preview {
    cursor: pointer;
    max-width: 250px;
    min-width: 90px;
    max-width: 250px;
    min-width: 90px;
    margin-bottom: 20px; 
    transition: transform 0.2s;
    text-align: center;
    animation: fadeIn 0.5s ease-out; 
    /* border: red solid; */
}

.movie-preview:hover {
    transform: scale(1.02);
}

.movie-preview img {
    border-radius: 0.23rem;
    object-fit: cover;
    width: 100%;
    height: auto;
}

.movie-preview span {
    font-size: 16px;
    color: rgb(183, 183, 183);
    margin-top: 10px;
}

#release-year {
    color: grey;
}

.greeting {
    color: rgb(175,175,175);
}

.link-to-request {
    cursor: pointer;
    color: lightblue;
    position: relative;
    text-decoration: none;
    font-weight: bolder;
    display: inline-block;
    padding-bottom: 3px; /* Adjust to position the underline */
}

.arrow {
    margin-left: 5px;
    color: lightblue;

    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 0.25s, transform 0.25s;
}

.link-to-request:hover + .arrow {
    opacity: 1;
    transform: translateX(0);
}

.link-to-request::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0; /* Position right at the text bottom */
    width: 100%;
    height: 0.5px; /* Initial thickness */
    background: currentColor;
    transition: transform 0.3s; /* Transition for the transform property */
    transform-origin: left center; /* Ensures the scaling is from the left */
    transform: scaleY(0.5); /* Initial scale */
}

.link-to-request:hover::after {
    transform: scaleY(3); /* Scale up the width on hover */
}

.greeting {
    color: rgb(175,175,175);
    /* margin-bottom: 20px; */
}

.link-to-request {
    cursor: pointer;
    color: lightblue;
    position: relative;
    text-decoration: none;
    font-weight: bolder;
    display: inline-block;
    padding-bottom: 3px; /* Adjust to position the underline */
}

.arrow {
    margin-left: 5px;
    color: lightblue;

    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 0.25s, transform 0.25s;
}

.link-to-request:hover + .arrow {
    opacity: 1;
    transform: translateX(0);
}

.link-to-request::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0; /* Position right at the text bottom */
    width: 100%;
    height: 0.5px; /* Initial thickness */
    background: currentColor;
    transition: transform 0.3s; /* Transition for the transform property */
    transform-origin: left center; /* Ensures the scaling is from the left */
    transform: scaleY(0.5); /* Initial scale */
}

.link-to-request:hover::after {
    transform: scaleY(3); /* Scale up the width on hover */
}



@media screen and (max-width: 600px) {
    .movie-grid {
        margin: 30px;
    }

    .greeting {
        margin-bottom: 10px;
    }

    .movie-preview span {
        font-size: 12px;
    }

    .top-bar {
        flex-direction: column;
        font-size: small;
    }

    .dropdowns {
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: red solid; */
    }

    .sort-dropdown .dropdown-toggle,
    .sort-dropdown .dropdown-menu,
    .sort-dropdown .dropdown-item,
    .genre-dropdown .dropdown-toggle,
    .genre-dropdown .dropdown-menu,
    .genre-dropdown .dropdown-item,
    .no-movies, .greeting  {
    font-size: small; /* Adjust the font size as needed */
    }

}

@media screen and (min-width: 600px) {
    .movie-grid {
        margin: 50px;
        /* border: red solid; */
    }
}