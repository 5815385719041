@media (min-width: 992px) {
    .navbar-collapse.justify-content-center {
        display: flex;
        justify-content: center;
    }
    #searchForm {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    #navbarScroll {
        justify-content: space-between;
    }
    #new-icon {
        margin-left: -5px;
        /* border: red solid; */
    }
}

@media (max-width: 992px) {
    #searchForm {
        margin-bottom: 15px;
    }
    .logout {
        margin-left: 10px;
    }
}

#searchbar {
    background-color: rgb(47, 53, 58);
    color: white;
    border: none;
}

#searchbar::placeholder {
    color: grey;
}

#navbar {
    z-index: 100;
}

.logout {
    transform: scale(1.5);
    padding-right: 10px;
    cursor: pointer;
    transition: transform 0.15s;
}

.logout:hover {
    transform: scale(1.75);
}


/* 33,37,41 */